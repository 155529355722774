import React from 'react';
import { Link } from 'gatsby';

import { useTranslation } from 'react-i18next';

import { routes } from '@/utils/routes';
import { slugify } from '@/utils/url';

interface CategoryCardProps {
  category: {
    name: string;
    image: {
      url: string;
    };
  };
}

const CategoryCard: React.FC<CategoryCardProps> = ({ category }) => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const translatedRoutes = routes(language);

  return (
    <Link
      to={`${translatedRoutes.categories}/${slugify(category.name)}`}
      key={category.name}
    >
      <div className="rounded-lg lg:h-[120px] lg:w-[120px] min-h-[100px] min-w-[100px] custom-shadow flex flex-col justify-center">
        <img
          src={category.image.url.split(`?`)[0]}
          alt={category.name}
          className="w-auto lg:max-h-[50px] max-h-[40px] mx-auto mb-3"
        />
        <p className="text-center text-sm">{category.name}</p>
      </div>
    </Link>
  );
};

export default CategoryCard;
