import React from 'react';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

import SearchBar from '@/components/SearchBar';

interface SearchBlocProps {
  source: ImageDataLike;
  alt: string;
  placeholder: string;
}

const SearchBloc: React.FC<SearchBlocProps> = ({
  source,
  alt,
  placeholder,
}) => {
  const { t } = useTranslation();

  return (
    <div className="grid relative xl:h-[50vh] h-44">
      <GatsbyImage
        image={getImage(source)}
        alt={alt}
        className="!absolute inset-0"
      />
      <div className="!absolute inset-0 bg-[#00000055]" />

      <div
        className="relative place-items-center grid"
        style={{
          gridArea: `1/1`,
        }}
      >
        <SearchBar placeholder={placeholder} title={t(`newSearch`)} />
      </div>
    </div>
  );
};

export default SearchBloc;
