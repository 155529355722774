import React from 'react';

import Content from '@/components/Content';

interface SimpleImageProps {
  source: string;
  sourceMobile: string;
  alt: string;
  link: string;
}

const SimpleImage = ({
  source,
  sourceMobile,
  alt,
  link,
}: SimpleImageProps): JSX.Element => (
  <Content>
    <a href={link} target="_blank" rel="noreferrer">
      <picture>
        <source
          media="(max-width: 768px)"
          srcSet={`${sourceMobile} 768w`}
          sizes="768px"
        />
        <source srcSet={`${source} 1280w`} sizes="1280px" />
        <img
          src={source}
          alt={alt}
          className="drop-shadow rounded-[10px] hover:cursor-pointer active:opacity-50"
        />
      </picture>
    </a>
  </Content>
);

export default SimpleImage;
