import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import { useTranslation } from 'react-i18next';

import Layout from '@/components/Layout';
import CategoryCard from '@/components/CategoryCard';
import SearchBloc from '@/components/SearchBloc';
import { SimpleImage } from '@/components/Ads';

const CategoriesTemplate = ({ data, pageContext: { categories } }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(`fr`);
  }, []);

  return (
    <Layout title={t(`metaTitle`)} description={t(`metaDescription`)}>
      <div className="xl:w-wide mx-auto text-[18px] px-6 lg:mt-16 mt-10 lg:mb-32 mb-20">
        <h1>{t(`title`)}</h1>
        <div className="lg:flex grid flex-row flex-wrap lg:justify-start rounded-lg lg:gap-6 gap-6 grid-cols-3">
          {categories.map((category, index) => (
            <CategoryCard category={category} key={index} />
          ))}
        </div>
      </div>

      <SimpleImage
        source="https://la-fraise-ads.s3.eu-west-3.amazonaws.com/Bugaboo/OaFgjQcz/banner.webp"
        sourceMobile="https://la-fraise-ads.s3.eu-west-3.amazonaws.com/Bugaboo/OaFgjQcz/block.webp"
        alt="Bugaboo Ads"
        link="https://campaign.lafraise.app/esVr"
      />

      <SearchBloc
        source={data.strawberry}
        alt="Fraises"
        placeholder='Essayez "fraise"'
      />
    </Layout>
  );
};

export default CategoriesTemplate;

export const query = graphql`
  query {
    locales: allLocale(
      filter: { ns: { in: ["general", "categories"] }, language: { eq: "fr" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    strawberry: file(relativePath: { eq: "strawberry.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
